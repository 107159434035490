import Link from "next/link";
import CountUp from "react-countup";
import { useEffect, useState } from "react";
import jackpot_amount from "../../lib/worker/api/jackpot_amount";
import { AppReducerStateProps } from "../../lib/store";
import { connect } from "react-redux";
import { pusher } from "components/PusherEvent";
import {
  CheckWithPrefixResponse,
  GetPrefixLevel,
} from "lib/infomation_vender/document";

var beforeAmount = 0.0;

interface HomeJackpotProps {
  information: CheckWithPrefixResponse;
}

function HomeJackpot(props: HomeJackpotProps) {
  const [jackpotAmountAfter, setJackpotAmountAfter] = useState<number>(0);
  const [jackpotAmountBefore, setJackpotAmountBefore] = useState<number>(0);
  const prefix = GetPrefixLevel(props.information);

  useEffect(() => {
    jackpot_amount({ prefix: prefix }).then((json) => {
      const total = json;
      setJackpotAmountBefore(json);
      setJackpotAmountAfter(beforeAmount);
      setJackpotAmountBefore(total);
      beforeAmount = total;
    });
  }, [prefix]);

  useEffect(() => {
    const channelJackpot = pusher.subscribe(
      "private-client-my-channel-reload-jackpot"
    );

    channelJackpot.bind(`reload`, () => {
      jackpot_amount({ prefix: prefix }).then((json) => {
        const total = json;
        setJackpotAmountAfter(beforeAmount);
        setJackpotAmountBefore(total);
        beforeAmount = total;
      });
    });

    return () => {
      channelJackpot.unbind_all();
      channelJackpot.disconnect();
    };
  }, []);

  return (
    <div style={{ paddingTop: 20 }}>
      <div className="container mb-margin-bottom-20">
        <div className="row center-row-content">
          <div
            style={{
              height: 60,
              width: 280,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${"https://aelwrzoecp.cloudimg.io/v7/_sevenduckciio_/normal/jackpot_blink.gif?w=300"})`,
            }}
          >
            <Link href={`/jackpot_deposit/${prefix}`}>
              <a style={{ textDecoration: "none" }}>
                <div
                  style={{ height: "100%", cursor: "pointer" }}
                  className="row center-row-content text-center"
                >
                  <CountUp
                    className="font-jackpot-ufa"
                    start={jackpotAmountAfter}
                    end={jackpotAmountBefore}
                    decimals={2}
                    suffix=" บาท"
                    separator=","
                  />
                </div>
              </a>
            </Link>
            {/* <div
            style={{ height: "100%" }}
            className="row center-row-content blink_me"
          >
            แจ็คพอต จ่ายออกไปแล้ว
          </div> */}
          </div>
          <Link href={`/jackpot_deposit/${prefix}`}>
            <a style={{ textDecoration: "none" }}>
              <div className="margin-top-10 select-div text-center text-white">
                ยอดสะสมแจ็คพอตคืออะไร ?
              </div>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeJackpot);
