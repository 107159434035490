import Link from "next/link";
import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";
import CommentShow from "components/view/comment_show";
import { MaxButton } from "components/view/button";
import React, { useEffect } from "react";
import get_comment_time, {
  GetCommentTimeResponse,
} from "lib/worker/api/get_comment_time";
import { useState } from "react";
import TimerComment from "components/view/timer_comment";
import get_comment, { GetCommentResponse } from "lib/worker/api/get_comment";
import {
  CheckWithPrefixResponse,
  PREFIX_SNAMBET,
  PREFIX_UFABER1,
  PREFIX_UFABETAG,
  PREFIX_UFABETAM,
  PREFIX_UFAGAME,
  PREFIX_UFAKUB555,
  PREFIX_UFAMM,
  PREFIX_UFAONLINE1,
  PREFIX_UFCASINO7,
} from "lib/infomation_vender/document";

interface HomeCommentAgProps {
  state: AppReducerStateProps;
  infomation: CheckWithPrefixResponse;
}

function HomeCommentAg(props: HomeCommentAgProps) {
  const { isLogin } = props.state.member;
  const [commentTime, setCommentTime] = useState<GetCommentTimeResponse[]>();
  const [comments, setComments] = useState<GetCommentResponse[]>([]);

  useEffect(() => {
    get_comment_time()
      .then((json) => {
        setCommentTime(json);
      })
      .catch((_error) => {});
    reload();
  }, []);

  const reload = () => {
    get_comment({
      prefix: props.infomation.prefix,
      start: comments.length,
      limit: 100,
    }).then((json) => {
      setComments([...comments, ...json]);
    });
  };

  const contentUfa = () => {
    switch (props.infomation.prefix) {
      case PREFIX_UFABER1:
        return (
          <>
            <span>UFABER1</span>
          </>
        );
      case PREFIX_UFABETAM:
        return (
          <>
            <span>UFABETAM</span>
          </>
        );
      case PREFIX_SNAMBET:
        return (
          <>
            <span>UFABETSNAM</span>
          </>
        );
      case PREFIX_UFAKUB555:
        return (
          <>
            <span>UFAKUB555</span>
          </>
        );
      case PREFIX_UFAGAME:
        return (
          <>
            <span>UFAGAME</span>
          </>
        );
      case PREFIX_UFAONLINE1:
        return (
          <>
            <span>UFAONLINE1</span>
          </>
        );
      case PREFIX_UFABETAG:
        return (
          <>
            <span>UFABET.AG</span>
          </>
        );
      case PREFIX_UFCASINO7:
        return (
          <>
            <span>UFCASINO7</span>
          </>
        );
      case PREFIX_UFAMM:
        return (
          <>
            <span>UFAMM</span>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="row row_with_dash">
        <div className="col-12">
          <div className="title-box">
            <div className="text-title">
              <span>กิจกรรมต่างๆ</span>
            </div>
          </div>
          <div className="view-background-spacer-ag"></div>
        </div>
      </div>
      <div className="border-ag color-font-card border-radius button-glowing">
        <h2
          className="margin-top-10"
          style={{ paddingTop: 10, marginLeft: 20, fontSize: 34 }}
        >
          <span>รีวิวจากสมาชิก {contentUfa()}</span>
        </h2>
        <div>
          <div className="container review-size-and-scroll">
            {(comments ?? []).map((value, i) => {
              return (
                <>
                  <CommentShow
                    key={`comment-${i}${value.HashKey}`}
                    User={value.Username ?? "-"}
                    Time={value.DisplayCreatedAt ?? "-"}
                    Message={value.Comment ?? "-"}
                  ></CommentShow>
                </>
              );
            })}
          </div>
          {isLogin && <div className="margin-top-10"></div>}
          <div
            className="margin-top-10 col-6 col-md-3 col-lg-2 center-row-content"
            style={{ paddingBottom: 10 }}
          >
            <Link href="/comment_all">
              <a style={{ textDecoration: "none" }}>
                <MaxButton
                  title="ดูรีวิวทั้งหมด"
                  className="text-center  bg-invite"
                ></MaxButton>
              </a>
            </Link>
          </div>
          {isLogin && (
            <div
              className="col-8 col-md-4 col-lg-3 center-row-content"
              style={{ paddingBottom: 10 }}
            >
              <Link href="/comment_all">
                <a style={{ textDecoration: "none" }}>
                  <MaxButton
                    title="รีวิว (รับเลย 300 uDiamond)"
                    className="text-center  bg-invite button-glowing "
                  ></MaxButton>
                </a>
              </Link>
            </div>
          )}
          {commentTime?.length != 0 && (
            <div
              className="row center-row-content"
              style={{ paddingBottom: 10 }}
            >
              <TimerComment></TimerComment>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(HomeCommentAg);
