import LayoutMaster from "../components/layout/layout_master";
import React, { useEffect, useState } from "react";
import { GetServerSideProps } from "next";
import { AppReducerStateProps } from "lib/store";
import {
  memberActionTypes,
  OnActionIsLogin,
  OnActionUpdateFavorite,
  OnActionUpdateLastHistoryGame,
  OnUpdateVenderInfomation,
} from "lib/store/member/reducer";
import { connect } from "react-redux";
import { GetAllGamesResponseResult } from "lib/worker/api/get_all_game";
import { GetTransactionGetHistoryLastPlayGame } from "lib/worker/api/get_transaction_get_history_last_play_game";
import Home from "../components/home/home";
import get_vender_domain_infomation, {
  GetVenderDomainInfomationResponse,
} from "lib/worker/api/get_vender_domain_infomation";
import { CenterProps } from "lib/all_type/center_props";
import get_all_content_by_prefix, {
  GetAllContentByPrefixResponse,
} from "lib/worker/api/get_all_content_by_prefix";
import {
  CheckWithPrefix,
  CheckWithPrefixResponse,
  PREFIX_SNAMBET,
  PREFIX_UF9Pro,
  PREFIX_UFABER1,
  PREFIX_UFABETAG,
  PREFIX_UFABETAM,
  PREFIX_UFAGAME,
  PREFIX_UFAKUB555,
  PREFIX_UFAMM,
  PREFIX_UFAONLINE1,
  PREFIX_UFCASINO7,
} from "lib/infomation_vender/document";
import { api_get_promotion_available_for_member } from "lib/worker/api/get_promotion_available_for_member";
import { GetPromotionTypesResponse } from "lib/worker/api/get_promotion_types";
import header_config from "lib/worker/api/header_config";
import {
  tileDescWithHeaderConfig,
  TitleDescHeaderModal,
} from "lib/helper/header_config_title_desc";
import HomeUfabetSnam from "components/home/home_ufabet_snam";
import HomeUfaBetAg from "components/home/home_ufabet_ag";

interface IndexProps extends CenterProps {
  state?: AppReducerStateProps;
  isLogin: boolean;
  onActionIsLogin?: OnActionIsLogin;
  onActionUpdateLastHistoryGame?: OnActionUpdateLastHistoryGame;
  onActiobUpdateFavorite?: OnActionUpdateFavorite;
  onUpdateVenderInfomation?: OnUpdateVenderInfomation;
  venderInfomation: GetVenderDomainInfomationResponse;
  infomation: CheckWithPrefixResponse;
  content: GetAllContentByPrefixResponse[];
  heeaderConfig: TitleDescHeaderModal;
}

const Index = (props: IndexProps) => {
  const [promotions, setpromotions] = useState<GetPromotionTypesResponse[]>([]);

  useEffect(() => {
    props.onActionIsLogin!(props.isLogin);
    props.onUpdateVenderInfomation!(props.venderInfomation);
    api_get_promotion_available_for_member({
      prefix: props.infomation.prefix,
    }).then(setpromotions);
  }, []);

  switch (props.infomation.prefix) {
    case PREFIX_UFABER1:
    case PREFIX_UFABETAM:
    case PREFIX_UFABETAM:
    case PREFIX_UFAKUB555:
    case PREFIX_UFAGAME:
    case PREFIX_UFAONLINE1:
    case PREFIX_UFCASINO7:
    case PREFIX_UF9Pro:
    case PREFIX_UFAMM:
      return (
        <LayoutMaster
          content_setting={{
            title: props.heeaderConfig.title,
            description: props.heeaderConfig.desc,
          }}
          information={props.infomation}
        >
          <div className="children-top" style={{ marginTop: -3 }}>
            <Home
              venderInfomation={props.venderInfomation}
              content={props.content}
              infomation={props.infomation}
              promotions={promotions}
            ></Home>
          </div>
        </LayoutMaster>
      );

    case PREFIX_UFABETAG:
      return (
        <LayoutMaster
          content_setting={{
            title: props.heeaderConfig.title,
            description: props.heeaderConfig.desc,
          }}
          information={props.infomation}
        >
          <div className="children-top" style={{ marginTop: -3 }}>
            <HomeUfaBetAg
              venderInfomation={props.venderInfomation}
              content={props.content}
              infomation={props.infomation}
              promotions={promotions}
            ></HomeUfaBetAg>
          </div>
        </LayoutMaster>
      );

    case PREFIX_SNAMBET:
      return (
        <LayoutMaster
          content_setting={{
            title: props.heeaderConfig.title,
            description: props.heeaderConfig.desc,
          }}
          information={props.infomation}
        >
          <div className="children-top" style={{ marginTop: -3 }}>
            <HomeUfabetSnam
              venderInfomation={props.venderInfomation}
              content={props.content}
              infomation={props.infomation}
              promotions={promotions}
            ></HomeUfabetSnam>
          </div>
        </LayoutMaster>
      );

    default:
      return <></>;
  }
};

export const getServerSideProps: GetServerSideProps<any> = async (context) => {
  const { req } = context;
  const infomation = CheckWithPrefix({ host: req.headers.host as string });
  try {
    const [venderInfomation, content, headerConfigs] = await Promise.all([
      get_vender_domain_infomation({
        domain: infomation.domain,
      }),
      get_all_content_by_prefix({
        prefix: infomation.prefix,
      }),
      header_config({
        prefix: infomation.prefix,
      }),
    ]);
    return {
      props: {
        isLogin: context.req.cookies.access_token != undefined,
        venderInfomation: venderInfomation,
        infomation: infomation,
        content: content,
        heeaderConfig: tileDescWithHeaderConfig("/", headerConfigs),
      },
    };
  } catch (error) {
    return {
      props: {
        isLogin: context.req.cookies.access_token != undefined,
        venderInfomation: {},
        infomation: infomation,
        content: [],
      },
    };
  }
};

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onActionIsLogin: (isLogin: boolean) =>
      dispatch({
        type: memberActionTypes.ACTION_STATUS_IS_LOGIN,
        payload: isLogin,
      }),
    onActionUpdateLastHistoryGame: (
      lastHistoryPlayGmaes: GetTransactionGetHistoryLastPlayGame[]
    ) =>
      dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_GAME_LAST_PLAY,
        payload: lastHistoryPlayGmaes,
      }),
    onActiobUpdateFavorite: (favoriteMyGames: GetAllGamesResponseResult[]) =>
      dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_GAME_FAVORITE,
        payload: favoriteMyGames,
      }),
    onUpdateVenderInfomation: (
      domainInfomation: GetVenderDomainInfomationResponse
    ) =>
      dispatch({
        type: memberActionTypes.ACTION_UPDATE_DOMAIN_INFOMATION,
        payload: domainInfomation,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
